import React, { useRef } from "react";
import emailjs from '@emailjs/browser';
import "./Join.css";

const Join = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_b6cq43d', 'template_ag4saul', form.current, 'VnBfbC3J8W1b-nMRZ') // Corrected here
      .then(
        (result) => {
          console.log('SUCCESS!', result.text);
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  return (
    <div className="join">
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-text">READY TO</span><span> LEVEL UP</span>
        </div>
        <div>
          <span>YOUR BODY</span> <span className="stroke-text">WITH US ?</span>
        </div>
      </div>
      <div className="right-j">
        <form ref={form} action="" className="email-container" onSubmit={sendEmail}>
          <input type="email" placeholder="Enter Your Email address" name="user_email" />
          <button className="btn btn-j">Join Now</button>
        </form>
      </div>
    </div>
  );
};

export default Join;
