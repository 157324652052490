import React, { useState } from 'react';
import './Plans.css';
import { plansData } from "../data/plansData";
import whiteTick from "../assets/whiteTick.png";
import Form from '../components/Form';

const Plans = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);  // State to control form visibility

  const openForm = () => {
    setIsFormOpen(true);  // Open the form when a plan's "Join Now" button is clicked
  };

  return (
    <div className="plans-container">
      <div className="blur blur-p1"></div>
      <div className="blur blur-p2"></div>
      <div className="program-header" style={{ gap: '2rem' }}>
        <span className="stroke-text">READY TO START</span>
        <span> YOUR JOURNEY</span>
        <span className="stroke-text"> NOW WITH US</span>
      </div>

      {/* Mapping through plansData to show all plans */}
      <div className="plans">
        {plansData.map((plan, i) => (
          <div className="plan" key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span>${plan.price}</span>
            <div className="features">
              {plan.features.map((feature, i) => (
                <div className="feature" key={i}>
                  <img src={whiteTick} alt=""/>
                  <span>{feature}</span>
                </div>
              ))}
            </div>
            <div><span>See more benefits </span></div> 
            <button className="btn" onClick={openForm}>Join Now</button>  {/* Open form when clicked */}
          </div>
        ))}
      </div>

      {/* Conditionally render the Form component if the form is open */}
      {isFormOpen && <Form setIsFormOpen={setIsFormOpen} />}
    </div>
  );
};

export default Plans;
