import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import './Form.css';

const Form = ({ setIsFormOpen }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    age: '',
    phone: '',
    height: '',
    weight: '',
    program: '',
    plan: '',
    paymentMethod: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);

    // Send form data using EmailJS
    emailjs
      .send('service_b6cq43d', 'template_7fy1kvn', formData, 'VnBfbC3J8W1b-nMRZ')
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          // Optionally close the form or show a success message
          setIsFormOpen(false);
        },
        (error) => {
          console.error('Error sending email:', error.text);
          // Optionally show an error message
        }
      );
  };

  return (
    <div className="form-container">
      <div className="form-overlay" onClick={() => setIsFormOpen(false)}></div>
      <form className="join-form" onSubmit={handleSubmit}>
        <h2>Join Us</h2>
        <input type="text" name="name" placeholder="Name" onChange={handleChange} required />
        <input type="email" name="email" placeholder="Email" onChange={handleChange} required />
        <input type="number" name="age" placeholder="Age" onChange={handleChange} required />
        <input type="tel" name="phone" placeholder="Phone Number" onChange={handleChange} required />
        <input type="number" name="height" placeholder="Height (cm)" onChange={handleChange} required />
        <input type="number" name="weight" placeholder="Weight (kg)" onChange={handleChange} required />
        
        <label>Select Program</label>
        <select name="program" onChange={handleChange} required>
          <option value="Strength Training">Strength Training</option>
          <option value="Cardio Training">Cardio Training</option>
          <option value="Fat Burning">Fat Burning</option>
          <option value="Health Fitness">Health Fitness</option>
        </select>

        <label>Select Plan</label>
        <select name="plan" onChange={handleChange} required>
          <option value="Basic">Basic</option>
          <option value="Premium">Premium</option>
          <option value="Pro">Pro</option>
        </select>

        <label>Payment Method</label>
        <select name="paymentMethod" onChange={handleChange} required>
          <option value="Phone Pay">Phone Pay</option>
          <option value="Net Banking">Net Banking</option>
          <option value="Credit/Debit Card">Credit/Debit Card</option>
        </select>

        <button type="submit" className="pay-now-btn">Pay Now</button>
      </form>
    </div>
  );
};

export default Form;
